import { API } from "./api.js";
export default {
  async createBlog(data) {
    try {
      const response = await API.post("/api/rest/v2/blog", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async haalBlogOp() {
    try {
      const response = await API.get("/api/rest/v2/blog");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async haalEeBlogId(id) {
    try {
      const response = await API.get(`/api/rest/v2/blog/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  
  async updateBlog(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/blog/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteBlog(id) {
    try {
      const response = await API.delete(`/api/rest/v2/blog/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async uploadBlogImage(id, formData) {
    try {
      const response = await API.post(`/api/rest/v2/folder/processing/upload/blog/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  }
  
  
};
