<template>
  <div>
    <v-container>
      <div>
        <v-text-field
          label="Titel"
          v-model="blog.titel"
          outlined
        ></v-text-field>
        <v-text-field
          label="Onderwerp"
          v-model="blog.onderwerp"
          outlined
        ></v-text-field>

        <v-select
          v-model="blog.categorie"
          :items="categories"
          id="name"
          outlined
          label="Categorie"
          item-value="naam"
          item-text="naam"
        ></v-select>
      </div>

      <v-divider> </v-divider>
      <vue-editor
        :editorToolbar="customToolbar"
        id="editor1"
        v-model="blog.content"
      >
      </vue-editor>

      <div class="text-center">
        <v-btn
          style="color:white;background-color:#cb403d;margin-right:10px"
          @click="goBack()"
        >
          Ga terug
        </v-btn>
        <v-btn
          @click="createBlog()"
          style="background-color: #147D81; color: white;"
        >
          Blog toevoegen
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import { Quill } from "vue-quill-editor";
import { VueEditor } from "vue2-editor";

// Generate code-friendly font names
function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}

const fontList = [
  "Arial",
  "Courier",
  "Garamond",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const fontNames = fontList.map((font) => getFontName(font));
const fonts = Quill.import("formats/font");
fonts.whitelist = fontNames;
Quill.register(fonts, true);

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function(font) {
  let fontName = getFontName(font);
  fontStyles +=
    ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" +
    fontName +
    "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" +
    fontName +
    "]::before {" +
    "content: '" +
    font +
    "';" +
    "font-family: '" +
    font +
    "', sans-serif;" +
    "}" +
    ".ql-font-" +
    fontName +
    "{" +
    " font-family: '" +
    font +
    "', sans-serif;" +
    "}";
});

const node = document.createElement("style");
node.innerHTML = fontStyles;
document.body.appendChild(node);

var Size = Quill.import("attributors/style/size");

Size.whitelist = [
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
];
Quill.register(Size, true);
import apiBlogCategorie from "../api/apiBlogCategorie";
import apiBlog from "../api/apiBlog";
export default {
  components: {
    VueEditor,
  },

  data() {
    return {
      image: [],
      editing: false,
      htmlForEditor: "",
      blog: {
        titel: "",
        content: "",
        onderwerp: "",
        categorie: "",
      },
      categories: [],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["bold", "italic", "underline"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ font: fonts.whitelist }],
        [
          {
            size: [
              "9px",
              "10px",
              "11px",
              "12px",
              "14px",
              "16px",
              "18px",
              "20px",
              "22px",
              "24px",
              "26px",
              "28px",
            ],
          },
        ],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["link", "image"],
        ["clean"],
      ],
    };
  },

  async mounted() {
    apiBlogCategorie.haalCategorieOp().then((data) => {
      this.categories = data;
    });
  },

  methods: {
    goBack() {
      this.$router.push("/Blogs");
    },
    resetForm() {
      this.blog.titel = "";
      this.blog.onderwerp = "";
      this.blog.content = "";
      this.blog.categorie = "";
    },
    async createBlog() {
      try {
        const response = await apiBlog.createBlog(this.blog);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        this.$router.push(`/Bloginformatie/${response.mergedBlog.id}`);
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    //Upload
    handleFileChange(files) {
      this.files = files;
    },

    async uploadFiles(blogId) {
      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          const formData = new FormData();
          const file = this.files[i];
          const newFileName = `${blogId}-${file.name}`;
          formData.append("file", file, newFileName);

          try {
            const response = await apiBlog.uploadFile(formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
            console.log("Bestand succesvol geüpload", response);
          } catch (error) {
            console.error("Er is een fout opgetreden bij het uploaden", error);
            this.$swal.fire("Upload fout", "", "error");
            return;
          }
        }
        this.files = [];
        this.$swal.fire(
          "Alle bestanden zijn succesvol geüpload",
          "",
          "success"
        );
      }
    },
  },
};
</script>

<style scoped>
.text-center {
  padding-top: 1%;
  text-align: center;
}

#editor,
#editor1 {
  height: 350px;
  width: 100%;
}

.projectButton {
  margin: 10px;
}
</style>
